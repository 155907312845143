import { ProductCompareButton as AdvoxBasicProductCompareButton } from 'AdvoxBasicComponent/ProductCompareButton/ProductCompareButton.component';

import './ProductCompareButton.extend.style';

/** @namespace AdvoxStrigob2c/Component/ProductCompareButton/Component/ProductCompareButton */
export class ProductCompareButton extends AdvoxBasicProductCompareButton {
    // TODO implement logic
}

export default ProductCompareButton;
