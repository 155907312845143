import { ProductCard as AdvoxBasicProductCard } from 'AdvoxBasicComponent/ProductCard/ProductCard.component';
import AddToCart from 'Component/AddToCart';
import Icon from 'Component/Icon';
import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';

import './ProductCard.extend.style';

/** @namespace AdvoxStrigob2c/Component/ProductCard/Component/ProductCard */
export class ProductCard extends AdvoxBasicProductCard {
    renderAddToCart() {
        const {
            product,
            product: { type_id, options = [], configurable_options: confOptions = {} },
            configurableVariantIndex,
            layout,
            showSelectOptionsNotification,
            inStock,
        } = this.props;

        const quantity = 1;
        const groupedProductQuantity = {};

        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const productOptionsData = {
            requiredOptions,
        };

        const redirectOnConfig =
            type_id === CONFIGURABLE &&
            Object.keys(confOptions).length !== Object.keys(this.getAttributesToShow()).length;

        if (type_id === BUNDLE || type_id === GROUPED || redirectOnConfig) {
            return (
                <button
                    block="Button AddToCart"
                    mods={{ layout, small: true, isFullOnHover: true }}
                    onClick={showSelectOptionsNotification}
                >
                    <Icon name="bag" />
                    <span>{__('To cart')}</span>
                </button>
            );
        }

        return (
            <AddToCart
                product={product}
                configurableVariantIndex={configurableVariantIndex}
                mix={{ block: 'ProductActions', elem: 'AddToCart' }}
                quantity={quantity}
                groupedProductQuantity={groupedProductQuantity}
                productOptionsData={productOptionsData}
                disabled={!inStock}
                layout={layout}
                isWithIcon
                isBtnTextLong
                isFullOnHover
            />
        );
    }
}

export default ProductCard;
