import { ProductAttributeValue as AdvoxStrigoB2CProductAttributeValue } from 'AdvoxStrigoB2CComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.pilot.style';

/** @namespace AdvoxPilot/Component/ProductAttributeValue/Component/ProductAttributeValue */
export class ProductAttributeValue extends AdvoxStrigoB2CProductAttributeValue {
    // TODO implement logic
}

export default ProductAttributeValue;
