import { ProductAttributeValue as AdvoxBasicProductAttributeValue } from 'AdvoxBasicComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.extend.style';

/** @namespace AdvoxStrigob2c/Component/ProductAttributeValue/Component/ProductAttributeValue */
export class ProductAttributeValue extends AdvoxBasicProductAttributeValue {
    // TODO implement logic
}

export default ProductAttributeValue;
