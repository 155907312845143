import { AddToCart as AdvoxBasicAddToCart } from 'AdvoxBasicComponent/AddToCart/AddToCart.component';
import Icon from 'Component/Icon';

import './AddToCart.extend.style';

/** @namespace AdvoxStrigob2c/Component/AddToCart/Component/AddToCart */
export class AddToCart extends AdvoxBasicAddToCart {
    renderCartIcon() {
        const { isWithIcon } = this.props;

        if (!isWithIcon) {
            return null;
        }

        return <Icon name="bag" />;
    }

    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick,
            layout,
            disabled,
            isBtnTextLong,
            isFullOnHover,
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        const btnText = isBtnTextLong ? __('Add To Cart') : __('To cart');

        return (
            <button
                onClick={buttonClick}
                block="Button AddToCart"
                mix={mix}
                mods={{ isLoading, layout, small: true, isFullOnHover }}
                disabled={isLoading || disabled}
            >
                {this.renderCartIcon()}
                <span>{isLoading ? __('Adding...') : btnText}</span>
            </button>
        );
    }
}

export default AddToCart;
