import Slick from 'react-slick';

import {
    AdvoxSlider as AdvoxBasicAdvoxSlider,
    NextArrow,
    PrevArrow,
} from 'AdvoxBasicComponent/AdvoxSlider/AdvoxSlider.component';

import './AdvoxSlider.override.style';

export { NextArrow, PrevArrow };

/** @namespace AdvoxStrigob2c/Component/AdvoxSlider/Component/AdvoxSlider */
export class AdvoxSlider extends AdvoxBasicAdvoxSlider {
    render() {
    // eslint-disable next-line
        const { settings, variant, heading, headingSide, children } = this.props;

        settings.nextArrow = <NextArrow />;
        settings.prevArrow = <PrevArrow />;

        // prevent infinite issue when we don't have enough slides
        // (slick would copy and collapse them to next row)
        if (Object.values(children).length < settings.slidesToShow) {
            settings.infinite = false;
        }

        return (
            <div block="AdvoxSlider" mods={{ variant }}>
                {heading && (
                    <p block="AdvoxSlider" elem="Heading" mods={{ headingSide }}>
                        {heading}
                    </p>
                )}
                <Slick {...settings}>{children}</Slick>
            </div>
        );
    }
}

export default AdvoxSlider;
