import { PasswordStrength as AdvoxBasicPasswordStrength } from 'AdvoxBasicComponent/PasswordStrength/PasswordStrength.component';

import './PasswordStrength.pilot.style';

/** @namespace AdvoxPilot/Component/PasswordStrength/Component/PasswordStrength */
export class PasswordStrength extends AdvoxBasicPasswordStrength {
    // TODO implement logic
}

export default PasswordStrength;
