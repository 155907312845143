import { AccordionComponent as AdvoxBasicAccordionComponent } from 'AdvoxBasicComponent/Accordion/Accordion.component';

import './Accordion.pilot.style';

/** @namespace AdvoxPilot/Component/Accordion/Component/AccordionComponent */
export class AccordionComponent extends AdvoxBasicAccordionComponent {
    // TODO implement logic
}

export default AccordionComponent;
