import { Field as AdvoxStrigoB2CField } from 'AdvoxStrigoB2CComponent/Field/Field.component';

import './Field.pilot.style';

/** @namespace AdvoxPilot/Component/Field/Component/Field */
export class Field extends AdvoxStrigoB2CField {
    // TODO implement logic
}

export default Field;
