import { withRouter } from 'react-router-dom';

import { ProductsSlider as AdvoxBasicProductsSlider } from 'AdvoxBasicComponent/ProductsSlider/ProductsSlider.component';

import './ProductsSlider.override.style';

/** @namespace AdvoxStrigob2c/Component/ProductsSlider/Component/ProductsSlider */
export class ProductsSlider extends AdvoxBasicProductsSlider {
    // TODO implement logic
}

export default withRouter(ProductsSlider);
