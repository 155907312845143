import { ProductPrice as AdvoxBasicProductPrice } from 'AdvoxBasicComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.extend.style';

/** @namespace AdvoxStrigob2c/Component/ProductPrice/Component/ProductPrice */
export class ProductPrice extends AdvoxBasicProductPrice {
    // TODO implement logic
}

export default ProductPrice;
