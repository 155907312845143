import { connect } from 'react-redux';

import {
    AddToCartContainer as AdvoxBasicAddToCartContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'AdvoxBasicComponent/AddToCart/AddToCart.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace AdvoxStrigob2c/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends AdvoxBasicAddToCartContainer {
    containerProps() {
        const { product, mix, disabled, layout, isWithIcon, isBtnTextLong, isFullOnHover } = this.props;
        const { isLoading } = this.state;

        return {
            isLoading,
            product,
            mix,
            disabled,
            layout,
            isWithIcon,
            isBtnTextLong,
            isFullOnHover,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
