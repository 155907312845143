import { IconContainer as AdvoxStrigoB2CIconContainer } from 'AdvoxStrigoB2CComponent/Icon/Icon.container';

/** @namespace AdvoxPilot/Component/Icon/Container/IconContainer */
export class IconContainer extends AdvoxStrigoB2CIconContainer {
    static defaultProps = {
        fill: '#005CAA',
        width: '25',
        height: '25',
        cursor: 'pointer',
    };
}

export default IconContainer;
