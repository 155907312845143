import domToReact from 'html-react-parser/lib/dom-to-react';
import { lazy } from 'react';

import Accordion from 'Component/Accordion/Accordion.container';
import AdvoxSlider from 'Component/AdvoxSlider';
import Brands from 'Component/Brands';
import CategoryBanner from 'Component/CategoryBanner';
import Icon from 'Component/Icon';
import Link from 'Component/Link';
import { Html as SourceHtml, WidgetFactory } from 'SourceComponent/Html/Html.component';
import { scrollToTop } from 'Util/Browser/Browser';
import isDesktop from 'Util/Desktop';
import isMobile from 'Util/Mobile';

export const DailyPromoProduct = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-recently-viewed" */
        'Component/DailyPromoProduct'
    )
);

export const AdvoxCatalogWidget = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "widget-recently-viewed" */
        'Component/AdvoxCatalogWidget'
    )
);

export { WidgetFactory };

/** @namespace AdvoxBasic/Component/Html/Component/Html */
export class Html extends SourceHtml {
    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget,
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks,
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput,
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript,
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle,
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable,
        },
        {
            query: { attribs: ['data-accordion'] },
            replace: this.replaceAccordionTabs,
        },
        {
            query: { attribs: ['data-icon'] },
            replace: this.renderIcon,
        },
        {
            query: { attribs: ['data-slider'] },
            replace: this.renderSlider,
        },
        {
            query: { attribs: ['data-brands-slider'] },
            replace: this.renderBrandsSlider,
        },
        {
            query: { attribs: ['data-promo-with-slider'] },
            replace: this.renderPromoWithSlider,
        },
        {
            query: { attribs: ['data-category-banner'] },
            replace: this.renderCategoryBanner,
        },
    ];

    renderBrandsSlider() {
        return <Brands />;
    }

    renderPromoWithSlider({ attribs, children }) {
        const isPromoList = attribs['data-list-from'] === 'desktop' && isDesktop.desktop();
        const heading = attribs['data-heading'];
        const isDailyPromoWithinSlider = attribs['data-within-slider-from'] === 'desktop' && isDesktop.desktop();
        const advoxCatalogWidget = children.filter(
            ({ name = '', attribs: { type } = {} }) => name === 'widget' && type === 'AdvoxCatalogWidget'
        );
        const advoxCatalogWidgetisActive = advoxCatalogWidget.length;
        const advoxCatalogWidgetAttribs = advoxCatalogWidgetisActive ? advoxCatalogWidget[0].attribs : {};
        const productsCount = advoxCatalogWidgetAttribs?.products_count || 8;

        return (
            <div
                className={`daily-promo-and-products ${
                    isPromoList && !isDailyPromoWithinSlider ? 'ProductsList' : 'ProductsSlider'
                } ${isDailyPromoWithinSlider ? 'PromoWithinSlider' : ''}`}
            >
                <p className="AdvoxSlider-Heading AdvoxSlider-Heading_headingSide_center">{heading}</p>
                <div className="daily-promo-and-products__content">
                    <DailyPromoProduct
                        productsCount={productsCount}
                        isDailyPromoWithinSlider={isDailyPromoWithinSlider}
                        advoxCatalogWidgetisActive={advoxCatalogWidgetisActive}
                        advoxCatalogWidgetAttribs={advoxCatalogWidgetAttribs}
                    />
                    {!isDailyPromoWithinSlider && isPromoList && (
                        <AdvoxCatalogWidget
                            isPromoList={isPromoList}
                            productsCount={productsCount}
                            advoxCatalogWidgetAttribs={advoxCatalogWidgetAttribs}
                        />
                    )}
                    {!isDailyPromoWithinSlider && !isPromoList && (
                        <AdvoxCatalogWidget
                            isSlider
                            productsCount={productsCount}
                            advoxCatalogWidgetAttribs={advoxCatalogWidgetAttribs}
                        />
                    )}
                </div>
            </div>
        );
    }

    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;
        const attributes = this.attributesToProps({ ...attrs, to: href });
        const icon = attribs['data-icon-link'];
        const isScrollToTop = attribs['scroll-to-top'];

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            // external links with icon example -> social link
            if (isAbsoluteUrl(href) && icon) {
                return <Link {...attributes}>{icon && <Icon name={icon} />}</Link>;
            }

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                if (isScrollToTop)
                    return (
                        <Link onClick={scrollToTop} {...attributes}>
                            {domToReact(children, this.parserOptions)}
                        </Link>
                    );

                return <Link {...attributes}>{domToReact(children, this.parserOptions)}</Link>;
            }
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceAccordionTabs({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        if (attribs['data-device'] === 'mobile' && !isMobile.any()) {
            return null;
        }

        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <Accordion {...attributes}>{domToReact(children, this.parserOptions)}</Accordion>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    renderIcon({ attribs }) {
        const attributes = this.attributesToProps(attribs);

        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <Icon name={attribs['data-icon']} {...attributes} />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    renderSlider({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        const settings = {
            slidesToShow: attributes['data-slidestoshow'] || 1,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: attributes['data-slidestoshowtablet'] || 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: attributes['data-slidestoshowmobile'] || 1,
                    },
                },
            ],
        };

        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <AdvoxSlider {...attributes} settings={settings}>
                {domToReact(children)}
            </AdvoxSlider>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    renderCategoryBanner({ children }) {
        const bannerLink = children.filter((item) => item.name === 'a');
        const bannerLinkHref = bannerLink.length ? bannerLink[0].attribs.href : '';

        if (!bannerLink.length) {
            return null;
        }

        const desktopImage = bannerLink[0].children.filter(
            (item) => item.name === 'img' && !item.attribs['data-banner-mobile']
        );
        const mobileImage = bannerLink[0].children.filter(
            (item) => item.name === 'img' && item.attribs['data-banner-mobile']
        );

        const desktopImageSrc = desktopImage.length ? desktopImage[0].attribs.src : '';
        const desktopImageAlt = desktopImage.length ? desktopImage[0].attribs.alt : '';
        const mobileImageSrc = mobileImage.length ? mobileImage[0].attribs.src : '';
        const mobileImageAlt = mobileImage.length ? mobileImage[0].attribs.alt : '';

        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <CategoryBanner
                bannerHref={bannerLinkHref}
                desktopImageSrc={desktopImageSrc}
                desktopImageAlt={desktopImageAlt}
                mobileImageSrc={mobileImageSrc}
                mobileImageAlt={mobileImageAlt}
            />
        );
    }
}

export default Html;
