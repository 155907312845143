import { ProductCard as AdvoxStrigoB2CProductCard } from 'AdvoxStrigoB2CComponent/ProductCard/ProductCard.component';

import './ProductCard.pilot.style';

/** @namespace AdvoxPilot/Component/ProductCard/Component/ProductCard */
export class ProductCard extends AdvoxStrigoB2CProductCard {
    // TODO implement logic
}

export default ProductCard;
