export * from 'AdvoxBasicComponent/Brands/Brands.config';

export const BRANDS_SLIDER_SETTINGS = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    infinite: true,
    draggable: true,
    responsive: [
        {
            breakpoint: 1380,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                centerMode: true,
                centerPadding: '10%',
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },

        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '18%',
            },
        },
    ],
};
