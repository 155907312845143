import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slick from 'react-slick';

import Icon from 'Component/Icon';

import { DEFAULT_SLIDER_SETTINGS } from './AdvoxSlider.config';

import './AdvoxSlider.style';
import './slick';

/** @namespace AdvoxBasic/Component/AdvoxSlider/Component/NextArrow */
export const NextArrow = ({ className, style, onClick }) => (
    <div className={className} style={{ ...style }} onClick={onClick} aria-hidden>
        <Icon name="arrow_right" fill="#808080" height="50" />
    </div>
);

/** @namespace AdvoxBasic/Component/AdvoxSlider/Component/PrevArrow */
export const PrevArrow = ({ className, style, onClick }) => (
    <div className={className} style={{ ...style }} onClick={onClick} aria-hidden>
        <Icon name="arrow_left" fill="#808080" height="50" />
    </div>
);

/** @namespace AdvoxBasic/Component/AdvoxSlider/Component/AdvoxSlider */
export class AdvoxSlider extends PureComponent {
    static propTypes = {
        settings: PropTypes.object,
        variant: PropTypes.oneOf(['default', 'hero', 'products']),
        heading: PropTypes.string,
        headingSide: PropTypes.oneOf(['left', 'center', 'right']),
    };

    static defaultProps = {
        settings: DEFAULT_SLIDER_SETTINGS,
        variant: 'default',
        heading: '',
        headingSide: 'center',
    };

    render() {
        // eslint-disable next-line
        const { settings, variant, heading, headingSide, children } = this.props;

        settings.nextArrow = <NextArrow />;
        settings.prevArrow = <PrevArrow />;

        // prevent infinite issue when we don't have enough slides
        // (slick would copy and collapse them to next row)
        if (Object.values(children).length < settings.slidesToShow) {
            settings.infinite = false;
        }

        return (
            <div block="AdvoxSlider" mods={{ variant }}>
                {heading && (
                    <p block="AdvoxSlider" elem="Heading" mods={{ headingSide }}>
                        {heading}
                    </p>
                )}
                <Slick {...settings}>{children}</Slick>
            </div>
        );
    }
}

export default AdvoxSlider;
