import { AddToCart as AdvoxStrigoB2CAddToCart } from 'AdvoxStrigoB2CComponent/AddToCart/AddToCart.component';

import './AddToCart.pilot.style';

/** @namespace AdvoxPilot/Component/AddToCart/Component/AddToCart */
export class AddToCart extends AdvoxStrigoB2CAddToCart {
    // TODO implement logic
}

export default AddToCart;
