import AdvoxSlider from 'AdvoxStrigoB2CComponent/AdvoxSlider';
import { Brands as AdvoxStrigoB2CBrands } from 'AdvoxStrigoB2CComponent/Brands/Brands.component';
import { BRANDS_SLIDER_SETTINGS } from 'AdvoxStrigoB2CComponent/Brands/Brands.config';

import './Brands.pilot.style';

/** @namespace AdvoxPilot/Component/Brands/Component/Brands */
export class Brands extends AdvoxStrigoB2CBrands {
    renderContent() {
        const { variant } = this.props;

        if (variant === 'BRANDS_GRID') {
            return (
                <div block="Brands" elem="Grid">
                    {this.renderBrandItems()}
                </div>
            );
        }

        return (
            <AdvoxSlider settings={BRANDS_SLIDER_SETTINGS} variant="default" heading={__('choose your style')}>
                {this.renderBrandItems()}
            </AdvoxSlider>
        );
    }
}

export default Brands;
