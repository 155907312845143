import { Brands as AdvoxBasicBrands } from 'AdvoxBasicComponent/Brands/Brands.component';
// import AdvoxSlider from 'Component/AdvoxSlider';
import Link from 'Component/Link';

import { BRANDS_SLIDER_SETTINGS, BRANDS_URL } from './Brands.config';

import './Brands.override.style';

/** @namespace AdvoxStrigob2c/Component/Brands/Component/Brands */
export class Brands extends AdvoxBasicBrands {
    renderBrandItem({ brand_id, value, meta_description, image, url_key }, index) {
        return (
            <div key={brand_id} block="Brands" elem="Item">
                {image && (
                    <div block="Brands" elem="ImgWrapper" mods={{ index: index % BRANDS_SLIDER_SETTINGS.slidesToShow }}>
                        <Link to={`${BRANDS_URL}/${url_key}`}>
                            <img src={image} alt={meta_description} />
                        </Link>
                    </div>
                )}
                <Link to={`${BRANDS_URL}/${url_key}`}>{value}</Link>
            </div>
        );
    }

    renderBrandItems() {
        const { brands } = this.props;

        return brands?.length && brands.map((brand, index) => this.renderBrandItem(brand, index));
    }
}

export default Brands;
