import { FieldSelect as AdvoxBasicFieldSelect } from 'AdvoxBasicComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.pilot.style';

/** @namespace AdvoxPilot/Component/FieldSelect/Component/FieldSelect */
export class FieldSelect extends AdvoxBasicFieldSelect {
    // TODO implement logic
}

export default FieldSelect;
