import { Field as AdvoxBasicField } from 'AdvoxBasicComponent/Field/Field.component';

import './Field.extend.style';

/** @namespace AdvoxStrigob2c/Component/Field/Component/Field */
export class Field extends AdvoxBasicField {
    // TODO implement logic
}

export default Field;
