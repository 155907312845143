import {
    AdvoxSlider as AdvoxStrigoB2CAdvoxSlider,
    NextArrow,
} from 'AdvoxStrigoB2CComponent/AdvoxSlider/AdvoxSlider.component';

import './AdvoxSlider.pilot.style';

export { NextArrow };

/** @namespace AdvoxPilot/Component/AdvoxSlider/Component/AdvoxSlider */
export class AdvoxSlider extends AdvoxStrigoB2CAdvoxSlider {
    // TODO implement logic
}

export default AdvoxSlider;
