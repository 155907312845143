import { ProductWishlistButton as AdvoxBasicProductWishlistButton } from 'AdvoxBasicComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.extend.style';

/** @namespace AdvoxStrigob2c/Component/ProductWishlistButton/Component/ProductWishlistButton */
export class ProductWishlistButton extends AdvoxBasicProductWishlistButton {
    // TODO implement logic
}

export default ProductWishlistButton;
