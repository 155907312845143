import { ProductLabel as AdvoxStrigoB2CProductLabel } from 'AdvoxStrigoB2CComponent/ProductLabel/ProductLabel.component';

import './ProductLabel.pilot.style';

/** @namespace AdvoxPilot/Component/ProductLabel/Component/ProductLabel */
export class ProductLabel extends AdvoxStrigoB2CProductLabel {
    // TODO implement logic
}

export default ProductLabel;
