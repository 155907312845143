import { ProductConfigurableAttributeDropdown as AdvoxStrigoB2CProductConfigurableAttributeDropdown } from 'AdvoxStrigoB2CComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

import './ProductConfigurableAttributeDropdown.pilot.style';

/** @namespace AdvoxPilot/Component/ProductConfigurableAttributeDropdown/Component/ProductConfigurableAttributeDropdown */
export class ProductConfigurableAttributeDropdown extends AdvoxStrigoB2CProductConfigurableAttributeDropdown {
    // TODO implement logic
}

export default ProductConfigurableAttributeDropdown;
